import React from 'react';
import { Box } from '@mui/material';
import './WarningBanner.css';

const WarningBanner = () => {
  return (
    <Box
      sx={{
        backgroundColor: 'red',
        color: 'white',
        padding: '10px',
        textAlign: 'center',
        fontSize: '1rem',
      }}
    >
      A feladatgyűjteményt csak a <b>2025 májusi (vagy későbbi) érettségire</b> készülő diákoknak ajánljuk, mert a kérdéstár még nem teljes, heti rendszerességgel kerülnek fel új feladatok.
      <br></br>A sikeres 2025 májusi emelt kémia érettségihez <b>minden tartalom időben rendelkezésedre fog állni</b>. 
      <br></br>A folyamatban lévő fejlesztésért cserébe a feladatgyűjteményt <b>kedvezményesen vásárolhatod meg</b> december 31-ig, 
      valamint <b>ajándékba adunk egy '25 júniusig tartó hozzáférést a lvlUP emelt kémia számítások <a href="https://kurzus.lvlmeup.hu/courses/kemia-szamolas" className="email-link" target="_blank">videókurzushoz</a>
      </b> (melynek éves díja 39.990 Ft lenne).<br></br>
      Ha vásárlás előtt kipróbálnád, vagy kérdésed van, keress bizalommal a <b><a href="mailto:info@kemszam.hu" className="email-link">info@kemszam.hu</a></b> e-mail címen.
    </Box>
  );
};

export default WarningBanner;
