import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams } from 'react-router-dom';
import { Typography, Box, Grid, CircularProgress, Tab, Tabs } from '@mui/material';
import Footer from '../components/Footer';
import LoginButton from '../components/Login';
import ChapterCard from '../components/ChapterCard';
import CustomCard from '../components/Card';
import StartPaymentButton from '../components/StartPaymentButton';
import Plyr from 'plyr-react';
import 'plyr-react/plyr.css';
import './Fejezetek.css';

const apiUrl = process.env.REACT_APP_API_URL;
const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;
const videoBaseUrl = process.env.REACT_APP_VIDEO_BASE_URL;

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ minHeight: '400px' }}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const Fejezetek = () => {
  const { isAuthenticated, user } = useAuth0();
  const { chapterBId: paramChapterBId } = useParams();
  const navigate = useNavigate();
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [exercises, setExercises] = useState([]);
  const [chapterADescription, setChapterADescription] = useState('');
  const [chapterBDescription, setChapterBDescription] = useState('');
  const [videos, setVideos] = useState([]);  // State for chapter-specific videos
  const [value, setValue] = useState(0);
  const [hasAccess, setHasAccess] = useState(false);
  const topLevelTabsRef = useRef(null);

  const loadMathJax = () => {
    if (window.MathJax) {
      window.MathJax.typesetPromise().catch((err) => console.log("MathJax error: ", err));
    }
  };

  const setTopLevelTabsRef = (node) => {
    if (node) {
      topLevelTabsRef.current = node;
      scrollToTabs();
    }
  };

  const scrollToTabs = () => {
    if (topLevelTabsRef.current) {
      const navbarHeight = 130;
      const elementPosition = topLevelTabsRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    const fetchChapters = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`${apiUrl}/chapters`);
        if (!response.ok) throw new Error('Failed to fetch chapters');
        const data = await response.json();
        setChapters(data);

        if (paramChapterBId) {
          const chapter = data.find((ch) => ch.chapterB_id === parseInt(paramChapterBId, 10));
          if (chapter) handleChapterCardClick(chapter, false);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchChapters();
  }, [paramChapterBId]);

  useEffect(() => {
    const checkAccess = async () => {
      if (isAuthenticated && user) {
        try {
          const response = await fetch(`${paymentApiUrl}/check-access`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: user.email }),
          });
          const data = await response.json();
          setHasAccess(data.has_access);
        } catch (error) {
          console.error("Error checking access:", error);
          setHasAccess(false);
        }
      }
    };

    checkAccess();
  }, [isAuthenticated, user]);

  const fetchChapterDescriptions = async (chapterBId) => {
    try {
      const response = await fetch(`${apiUrl}/chapterB/${chapterBId}`);
      if (!response.ok) throw new Error('Failed to fetch chapter descriptions');
      const data = await response.json();
      setChapterADescription(data.chapterA_description || '');
      setChapterBDescription(data.description || '');
      setTimeout(loadMathJax, 0);
    } catch (error) {
      console.error('Error fetching chapter descriptions:', error);
    }
  };

  const fetchVideosForChapter = async (chapterBId) => {
    try {
      const response = await fetch(`${apiUrl}/videos_by_chapter/${chapterBId}`);
      if (!response.ok) throw new Error('Failed to fetch videos');
      const videoData = await response.json();
      const videosWithFullUrl = videoData.map((video) => ({
        ...video,
        URL: `${videoBaseUrl}${video.video_link}`, // Apply base URL
      }));
      setVideos(videosWithFullUrl);
    } catch (error) {
      console.error('Error fetching videos:', error);
      setVideos([]);
    }
  };

  const fetchExercisesForChapter = async (chapterB, responseStatus = 'all') => {
    setLoading(true);
    setError(null);
    try {
      const url = responseStatus === 'all'
        ? `${apiUrl}/all_exercises_by_chapter?chapterB_id=${chapterB}`
        : `${apiUrl}/chapters/${chapterB}/exercises?user_id=${encodeURIComponent(user.sub)}&response_status=${responseStatus}`;
      const response = await fetch(url);
      if (!response.ok) throw new Error('Failed to fetch exercises');
      const data = await response.json();
      setExercises(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const selectChapter = (chapter) => {
    if (chapter && chapter.chapterB_id) {
      setSelectedChapter(chapter);
      fetchChapterDescriptions(chapter.chapterB_id);
      fetchVideosForChapter(chapter.chapterB_id);
      setValue(0);
      fetchExercisesForChapter(chapter.chapterB_id);
    }
  };

  const handleChapterCardClick = (chapter, shouldNavigate = true) => {
    selectChapter(chapter);
    if (shouldNavigate) navigate(`/fejezetek/${chapter.chapterB_id}`);
  };

  useEffect(() => {
    if (!loading && selectedChapter) scrollToTabs();
  }, [loading, selectedChapter]);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue !== 0 && selectedChapter) fetchExercisesForChapter(selectedChapter.chapterB_id, valueToFilter(newValue));
  };

  const valueToFilter = (value) => {
    switch (value) {
      case 1: return 'all';
      case 2: return 'nem_ment';
      case 3: return 'sikerult';
      case 4: return 'meg_nem_probaltam';
      default: return 'all';
    }
  };

  const getNoExercisesMessage = () => {
    switch (value) {
      case 2: return 'Nincs nem sikerült feladat.';
      case 3: return 'Nincs sikerült feladat.';
      case 4: return 'Nincs még nem próbált feladat.';
      default: return 'Nincs elérhető feladat.';
    }
  };

  if (!isAuthenticated) {
    return (
      <div>
        <Typography mt={10} mb={3}>
          Hogy lásd milyen fejezetekből áll a számolási példatár és kedvedre böngéssz a feladatok között, kérlek jelentkezz be!
        </Typography>
        <LoginButton />
        <Footer />
      </div>
    );
  }

  if (!hasAccess) {
    return (
      <div>
        <Typography mt={10} mb={3}>
          Az elérés lejárt vagy nem elérhető. Kérlek frissítsd az előfizetésed!
        </Typography>
        <StartPaymentButton text="Megvásárolom (5.990 Ft / év)" />
        <Footer />
      </div>
    );
  }

  return (
    <div style={{ margin: '0 auto', maxWidth: '1000px' }}>
      <Box mt={3} mb={2} mr={2} ml={2} paddingBottom={10}>
        <Typography variant="h5" mb={3}>Fejezetek</Typography>
        <Grid container spacing={3}>
          {chapters.length === 0 ? (
            <CircularProgress />
          ) : (
            chapters.map((chapter, index) => (
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                <ChapterCard chapter={chapter} onClick={() => handleChapterCardClick(chapter)} />
              </Grid>
            ))
          )}
        </Grid>
        {selectedChapter && (
          <div ref={setTopLevelTabsRef}>
            <Typography variant="h5" mt={7} mb={3}>Feladatok: {selectedChapter.chapterB}</Typography>
            <Box className="tabs-container">
              <Box className="tabs-wrapper">
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  TabIndicatorProps={{ style: { backgroundColor: '#1976d2' } }}
                >
                  <Tab label="Elméleti összefoglaló" {...a11yProps(0)} className="tab-root" />
                  <Tab label="Összes" {...a11yProps(1)} className="tab-root" />
                  <Tab label="Nem sikerült" {...a11yProps(2)} className="tab-root" />
                  <Tab label="Sikerült" {...a11yProps(3)} className="tab-root" />
                  <Tab label="Nem próbáltam" {...a11yProps(4)} className="tab-root" />
                </Tabs>
              </Box>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Box textAlign="center" style={{ maxWidth: '1250px', margin: '0 auto' }}>
                <Typography mt={2} mb={3} align="left" style={{ fontSize: '20px' }}>
                  <strong>Főfejezet - {selectedChapter.chapterA}: </strong>
                </Typography>
                <Typography
                  mt={2}
                  align="left"
                  className="mathjax-content"
                  style={{ fontSize: '17px', textAlign: 'left', marginLeft: '10px', marginRight: '10px' }}
                  dangerouslySetInnerHTML={{ __html: chapterADescription }}
                />
                <Typography mt={5} mb={3} align="left" style={{ fontSize: '20px' }}>
                  <strong>Alfejezet - {selectedChapter.chapterB}:</strong>
                </Typography>
                <Typography
                  mt={2}
                  mb={2}
                  align="left"
                  className="mathjax-content"
                  style={{ fontSize: '17px', textAlign: 'left', marginLeft: '10px', marginRight: '10px' }}
                  dangerouslySetInnerHTML={{ __html: chapterBDescription }}
                />
                {videos.length > 0 && (
                  <Box mt={4}>
                    <Typography mt={5} mb={3} align="left" style={{ fontSize: '20px' }}>
                      <strong>Videó:</strong>
                    </Typography>
                    {videos.map((video) => (
                      <Box key={video.id} sx={{ maxWidth: 600, margin: '0 auto', mb: 3 }}>
                        <Typography variant="body1" align="center" mb={1}>{video.title}:</Typography>
                        <Plyr source={{ type: 'video', sources: [{ src: video.URL }] }} />
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </CustomTabPanel>
            {[1, 2, 3, 4].map((index) => (
              <CustomTabPanel value={value} index={index} key={index}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    {exercises.length === 0 ? (
                      <Typography>{getNoExercisesMessage()}</Typography>
                    ) : (
                      <Grid container spacing={3}>
                        {exercises.map((exercise) => (
                          <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={exercise.id}>
                            <CustomCard exercise={exercise} />
                          </Grid>
                        ))}
                      </Grid>
                    )}
                    <Box sx={{ minHeight: '250px' }}></Box>
                  </>
                )}
              </CustomTabPanel>
            ))}
          </div>
        )}
      </Box>
      {error && <Typography color="error">{error}</Typography>}
      <Footer />
    </div>
  );
};

export default Fejezetek;
