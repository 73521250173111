import React from 'react';
import { Container, Divider, Box, Button } from '@mui/material';
import Footer from '../components/Footer';
import StartPaymentButton from '../components/StartPaymentButton';
import WarningBanner from '../components/WarningBanner';
import './Fooldal.css';

const Fooldal = () => {
  return (
    <div>
      <WarningBanner /> {/* Add the WarningBanner component here */}
      <HeroSection />
      <HowToUseSection />
      <StatisticsSection />
      <JoinUsSection />
      <LvlUpSection />
      <Footer />
    </div>
  );
};

const HeroSection = () => {
  return (
    <Container className="section">
      <Box className="section-content">
        <div className="content-wrapper">
          <div className="text">
            <h1 className="hero-title">Egy modern feladatgyűjtemény, hogy tényleg ötösöd legyen kémiából</h1>
            <p className="hero-paragraph" style={{ fontSize: '1.3rem', lineHeight: '1.7', marginTop: '40px', marginLeft: '20px', marginRight: '50px' }}>
              Lehet hatékonyabban készülni a kémiai számolási példák megoldására? Szerintünk igen. A kémszám.hu modern és kényelmes alternatívát nyújt a hagyományos feladatgyűjteményekkel szemben.
            </p>
            <div className="price-section" style={{ textAlign: 'center', marginTop: '20px' }}>
              <span className="crossed-out-price">9.990 Ft</span>
            </div>
            <div className="gift-section" style={{ 
                textAlign: 'center', 
                marginTop: '10px',
                marginBottom: '20px', 
                padding: '10px 15px', 
                backgroundColor: '#e8f5e9', 
                color: '#388e3c', 
                fontWeight: 'bold', 
                fontSize: '1.15rem', 
                borderRadius: '4px', 
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
              }}>
              🎁 Ajándék: kémia érettségi számolások <a href="https://kurzus.lvlmeup.hu/courses/kemia-szamolas" className="email-link" target="_blank">videókurzus</a>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '40px' }}>
              <StartPaymentButton text="Befizetek (5.990 Ft / év)" />
            </div>
          </div>
          <div className="image" style={{ marginTop: '20px', marginBottom: '-4px' }}>
            <img src="/assets/mobile_phone.png" alt="Hero Image" />
          </div>
        </div>
      </Box>
      <Divider style={{ margin: '0', width: '100%', borderTop: '15px solid #ccc' }} />
    </Container>
  );
};

const HowToUseSection = () => {
  return (
    <Container className="section">
      <Box className="section-content calculator-section" style={{ marginTop: '40px'}}>
        <div className="text-wrapper">
          <h2 className="calculator-title">Interaktív, könnyen használható</h2>
          <div className="text">
            <p>Felejtsd el, hogy könyveket hurcolsz magaddal vagy átláthatatlan PDF-ekben kutakodsz a megoldások után! A kémszám.hu egy interaktív feladatgyűjtemény, ahol bármikor hozzáférhetsz a kémiai számolási feladatokhoz. Minden feladat egy helyen, könnyen kereshető és áttekinthető formában áll rendelkezésedre. A megoldások levezetése pedig mindig csak egy gombnyomásnyira van.</p>
          </div>
        </div>
        <div className="image">
          <iframe
            height="170"
            src="https://www.youtube.com/embed/NpEaa2P7qZI?si=_bexlfIWWor65ewc"
            title="YouTube video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Box>
      <Divider style={{ margin: '40px auto', width: '100%', borderTop: '15px solid #ccc' }} />
    </Container>
  );
};

const StatisticsSection = () => {
  return (
    <Container className="section">
      <Box className="section-content two-column reverse">
        <div className="text-wrapper">
          <h2 className="statistics-title">Légy tudatában, hogyan is haladsz</h2>
          <p className="statistics-paragraph">A siker kulcsa a tudatos felkészülés. A kémszám.hu részletes statisztikákat biztosít, hogy nyomon tudd követni fejlődésedet. Megjelölheted a feladatokat, melyeket nem sikerült megoldanod. Ezeket vedd elő újra és újra, amíg biztos nem leszel a tudásodban. Láthatod, mely témakörökben vagy erős és melyekben szükséges még a gyakorlás. Így hatékonyabban készülhetsz fel az emelt szintű kémia érettségire.</p>
        </div>
        <div className="image">
          <img src="/assets/statistics.jpg" alt="Statistics Image" />
        </div>
      </Box>
      <Divider style={{ margin: '40px auto', width: '100%', borderTop: '15px solid #ccc' }} />
    </Container>
  );
};

const JoinUsSection = () => {
  return (
    <Container className="section">
      <Box className="section-content calculator-section">
        <div className="text-wrapper">
          <h2 className="how-to-use-title">Csatlakozz hozzánk és juss be az egyetemre!</h2>
          <div className="text">
            <p>Akár dolgozatra készülsz, akár az érettségire, a kémszám.hu-val a felkészülés egyszerű és hatékony. Csatlakozz hozzánk, és tapasztald meg, hogyan teheted könnyebbé és eredményesebbé a kémiai számolási feladatok gyakorlását.</p>
            <div className="price-section" style={{ textAlign: 'center', marginTop: '20px' }}>
              <span className="crossed-out-price">9.990 Ft</span>
            </div>
            <div className="gift-section" style={{ 
                textAlign: 'center', 
                marginTop: '10px',
                marginBottom: '20px', 
                padding: '10px 15px', 
                backgroundColor: '#e8f5e9', 
                color: '#388e3c', 
                fontWeight: 'bold', 
                fontSize: '1.15rem', 
                borderRadius: '4px', 
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
              }}>
              🎁 Ajándék: kémia érettségi számolások <a href="https://kurzus.lvlmeup.hu/courses/kemia-szamolas" className="email-link" target="_blank">videókurzus</a>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
              <StartPaymentButton text="Megvásárolom (5.990 Ft / év)"/>
            </div>
          </div>
        </div>
        <div className="image">
          <img src="/assets/winner.png" alt="Join Image" />
        </div>
      </Box>
      <Divider style={{ margin: '40px auto', width: '100%', borderTop: '15px solid #ccc' }} />
    </Container>
  );
};

const LvlUpSection = () => {
  return (
    <Container className="section">
      <Box className="section-content two-column reverse" style={{ marginBottom: '100px' }}>
        <div className="text-wrapper">
          <h2 className="statistics-title">Teljes körű kémia érettségi felkészítőt keresel?</h2>
          <p className="statistics-paragraph">
            A kémszám.hu-t a <a href="https://lvlmeup.hu" target="_blank">lvlmeup.hu</a> csapata készítette. A lvlUP egy hibrid érettségi felkészítő.
            E-learning tananyag segítségével (első sorban <b>videókkal</b>) a saját tempódban haladhatsz. Tudásod
            <b> interaktív tesztek</b> segítségével ellenőrizheted. Heti rendszerességű, <b>tanteremben és online is követhető
            óráinkon</b> pedig feladatmegoldásokkal és a ti kérdéseitekkel, problémáitokkal foglalkozunk.
            A kémszám.hu feladatgyűjteményt a lvlUP előfizetői ingyenesen használhatják.<br></br><br></br>
            Ha érdekel a dolog, nézz körül a weboldalunkon!
            </p>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => window.open('https://lvlmeup.hu', '_blank')}
              >
                Érdekel
              </Button>
            </div>
        </div>
        <div className="image">
          <img src="/assets/lvlUP_logo.png" alt="lvlUP logo" />
        </div>
      </Box>
    </Container>
  );
};

export default Fooldal;
